<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="1"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12 d-flex justify-content-between">
              <h6>土地管理</h6>
              <router-link class="text-primary" to="/user/land/add" target="_blank">
                <img src="@/assets/icon/fbtd.png" style="width: 24px;"/>
                <span class="ml-1">发布土地信息</span>
              </router-link>
            </div>
            <div class="col-12">
              <div class="clearfix mt-4 d-flex align-items-center">
                <div class="float-left">
                  更新时间：
                </div>
                <div class="float-left ml-2">
                  <b-form-input type="date" v-model="para.param.startTime"></b-form-input>
                </div>
                <div class="float-left ml-2 mr-2">
                 至
                </div>
                <div class="float-left">
                  <b-form-input type="date" v-model="para.param.endTime"></b-form-input>
                </div>
                <div class="float-left ml-3">
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(1)" :class="{'active':yues==1}" >近一月</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(3)" :class="{'active':yues==3}">近三月</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(6)" :class="{'active':yues==6}">近半年</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(12)" :class="{'active':yues==12}">近一年</a>
                </div>
              </div>

              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                  流转类型：
                </div>
                <div class="float-left ml-4">
                  <div class="row">
                    <a href="javascript:;" class="mr-3 px-2 py-1"
                       @click="para.param.flowType = '', onScreenClick('circulationType','')"
                       :class="{'active': para.param.flowType == ''}">全部</a>
                    <a href="javascript:;" @click="onScreenClick('circulationType',v.id)"
                       v-for="v in dict.circulationType" :key="v.id"
                       class="mr-3 px-2 py-1"
                       :class="{'active':para.param.flowType==v.id }">{{ v.text }}</a>
                  </div>
                </div>
              </div>

              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                  土地面积：
                </div>
                <div class="float-left ml-4">
                  <div class="row">

                    <a href="javascript:;" @click="onScreenClick('areaRangeM',v.id)"
                       v-for="v in dict.areaRangeM" :key="v.id"
                       class="mr-3 px-2 py-1"
                       :class="{'active':areaRangeM==v.id }">{{ v.text }}</a>
                  </div>
                </div>
              </div>

              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                   标题检索：
                </div>
                <div class="float-left ml-4">
                  <div class="row">
                    <input class="form-control mr-sm-2" type="search" placeholder="输入土地关键词" v-model="para.param.title">
                  </div>
                </div>
                <div class="float-left ml-3">
                  <b-button size="sm" variant="success" type="submit" style="width:100px" @click="onScreenClick('ss','')">搜索</b-button>
                </div>
                
              </div>

            </div>
            <div class="col-12 mt-4 mb-4">
              <div class="card border-0">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-centered border mb-0">
                      <thead class="table-light">
                        <tr>
                          <th class="w-65">土地信息</th>
                          <th>更新时间</th>
                          <th>状态</th>
                          <th style="width: 80px">操作</th>
                        </tr>
                      </thead>
                      <tbody v-if="list.records && list.records.length > 0">
                        <tr v-for="(m,i) in list.records" :key="i">
                          <td>
                            <div class="clearfix">
                              <div v-if="m.imageUrl" class="float-left mr-3">
                                <img :src="m.imageUrl" class="width-80 rounded"/>
                              </div>
                              <div class="float-left">
                                  <h6>{{m.title}}</h6>
                                  <div class="mt-2 text-gray-777">
                                    <span>价格：{{m.price}}万</span>
                                    <span class="ml-2">年限：{{m.years}}年</span>
                                  </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="mt-1">{{m.createdAt}}</div>
                          </td>

                          <td>
                            <h5>
                              <span class="badge badge-danger f-12"
                                >{{getneedsStatusValue(m.needsStatus)}}
                              </span
                              >
                            </h5>
                          </td>
                          <td>
                            <div class="mt-1 mb-1 f-16">
                              <router-link :to="'/user/land/add?id=' + m.id" target="_blank">
                                <b-icon icon="pencil"></b-icon>  
                              </router-link>
                              <a @click="onDelete(m.id,i)" href="javascript:;" class="ml-2">
                                <b-icon icon="trash"></b-icon>  
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                      <tr>
                        <td colspan="4">
                          <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                            <div class="text-gray-777">没有找到土地信息</div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="list && list.total > list.size"
                       class="my-4 d-flex justify-content-center">
                    <b-pagination @change="onPageChange" :total-rows="list.total"
                                  :per-page="list.size"></b-pagination>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "landlist",
  data() {
    return {
      list:[],
      areaRangeM:"",
      yues:"",
      para:{
        param:{
          classId:"",
          title:"",
          flowType:"",
          measureAreaMin:"",
          measureAreaMax:"",
          yearsMin:"",
          yearsMax:"",

        },
        size: 15,
        current: 1,
      }
    };
  },
  computed:{
    ...mapGetters(["dict"]),
    getneedsStatusValue () {
      return function(id) {
        if(this.dict && this.dict.needsStatus) {
          let m = this.dict.needsStatus.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
  },
  methods: {
    onPageChange(i) {
      this.para.current = i;
      this.getList();
    },
    getList() {
       this.$http.post(this.$api.user.findMyLandList,this.para).then((res)=> {
          if (res.code == 1) {
            this.list = res.result;
            debugger;
            this.list.records = res.result.records.map(x => {
              return {
                id: x.id,
                title: x.title,
                price: x.price,
                years: x.years,
                createdAt: x.createdAt,
                needsStatus:x.needsStatus,
                imageUrl: x.userNeedsFiles.find(x=>x.uploadFileType == 1)
              }
            });
          }
      })
    },
    onScreenClick(type, id) {
      this.para.current = 1;
      switch (type) {
        case 'circulationType':
          this.para.param.flowType = id;
          break;
        case 'areaRangeM':
          this.areaRangeM = id;
          let p = this.dict.areaRangeM.find(x => x.id == id);
          if (p) {
            this.para.param.measureAreaMin = p.measureAreaMin;
            this.para.param.measureAreaMax = p.measureAreaMax;
          }
          break;
        case 'years':
          this.years = id;
          let y = this.dict.years.find(x => x.id == id);
          if (y) {
            this.para.param.yearsMin = y.yearMin;
            this.para.param.yearsMax = y.yearMax;
          }
          break;
      }
      this.getList();

    },
    //获取当前日期几个月内的数据
    createBeginDate(yues) {
      this.yues = yues;
      var date = new Date();
      date.setMonth(date.getMonth() - yues);
      date.toLocaleDateString()
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d

      var dqdate = new Date();
      dqdate.toLocaleDateString()
      var b = dqdate.getFullYear()
      var e = dqdate.getMonth() + 1
      e = e < 10 ? ('0' + e) : e
      var q = dqdate.getDate()
      q = q < 10 ? ('0' + q) : q

      const dqtime = b + '-' + e + '-' + q;
      this.para.param.startTime = time;
      this.para.param.endTime = dqtime;
      this.getList();
    },
     onDelete(id, i) {
      this.$dialog.alert({
					title: "温馨提示", 
					message: "是否删除该条土地信息?", 
					showCancelButton: true 
				}).then(() => {
           this.$http.delete(this.$api.user.deleteLand + '/' + id).then(res => {
              if(res.code == 1) {
                this.$model.toast('删除成功!', 2000);
                this.getList();
              }
          }).catch(error=> {
            this.$model.toast(error.reason,2000);
          })
				})
    },
  },
  created () {
    this.getList();
  }

};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
.form-control {
  height:31px;
}
.active {
  background: #00cc00;
  padding: 5px;
  color: #FFFFFF;
  border-radius: 4px;
}
</style>
